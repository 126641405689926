import React from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { RootState } from '@mindfulchefuk/types/store'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'

export const NotificationSuccessVoucherApplied = () => {
  const router = useRouter()

  const voucherQuery = router.query.savedVoucherCode as string
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )

  const voucherToUse = voucherQuery ?? savedVoucherCode

  const data = getDiscountConfig(voucherToUse)

  if (!voucherToUse.length || !data || isAuthenticated()) return null

  return (
    <BannerNotification
      variant="success"
      title={`Your discount code "${voucherToUse}" has been applied`}
      id="success-voucher-applied"
      data-testid="success-voucher-applied"
    >
      {data.totalDiscountSentence}
    </BannerNotification>
  )
}
