import {
  APISubscription,
  Subscription,
} from '@mindfulchefuk/features/Subscription/interfaces'

export const transformSubscription = (
  subscription: APISubscription
): Subscription => {
  const {
    cancelled_at: cancelledAt,
    default_delivery_option_id: defaultDeliveryOptionId,
    delivery_frequency_weeks: deliveryFrequencyWeeks,
    delivery_weekdays: deliveryWeekdays,
    deliveries_updating_failed_at: deliveryUpdatingFailedAt,
    deliveries_updating_since: deliveryUpdatingSince,
    excluded_food_groups: excludedFoodGroups,
    id: subscriptionId,
    meal_count_per_delivery: mealCountPerDelivery,
    meal_plan: mealPlan,
    portion_count_per_meal: portionCountPerMeal,
    shipped_legacy_deliveries_count: shippedLegacyDeliveriesCount,
    paused_until: pausedUntil,
    delinquent_since: delinquentSince,
    unpaid_deliveries_since: unpaidDeliveriesSince,
    unpaid_deliveries_count: unpaidDeliveriesCount,
  } = subscription

  return {
    subscriptionId,
    cancelledAt,
    defaultDeliveryOptionId,
    deliveryFrequencyWeeks,
    deliveryWeekdays,
    deliveryUpdatingFailedAt,
    deliveryUpdatingSince,
    excludedFoodGroups,
    mealCountPerDelivery,
    mealPlan,
    portionCountPerMeal,
    shippedLegacyDeliveriesCount,
    pausedUntil,
    delinquentSince,
    unpaidDeliveriesSince,
    unpaidDeliveriesCount,
  }
}
