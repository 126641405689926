export const GENERIC_VOUCHER_CONFIG: DiscountConfig = {
  totalDiscountSentence: `Voucher applied`,
  futureDiscountSentence: 'Voucher applied',
  thisBoxDiscount: 'Discount',
  discountPeriod: 0,
}

export const genericDiscountCopy = 'Get 25% off your first 4 boxes'

export interface DiscountConfig {
  /**
   * Sentence describing the total discount (no punctuation)
   * @example "£40 off across your first 4 boxes"
   * @example "25% off your first 4 boxes"
   */
  totalDiscountSentence: string
  /**
   * Sentence describing the discount for future boxes (with punctuation)
   * @example "Plus £30 off across your next 3 boxes too!"
   * @example "Plus 25% off your second box too!"
   */
  futureDiscountSentence: string
  /**
   * Shortest possible phrase that describes the discount
   * @example "£10 off"
   * @example "25% off"
   * @example "Discount"
   */
  thisBoxDiscount: string
  /**
   * Time period of the discount in weeks - if not applicable, set to 0
   */
  discountPeriod: number
}

const coreDiscountsConfig: (DiscountConfig & { code: string | string[] })[] = [
  {
    code: [
      '10X2',
      'HEALTHY',
      'DEL21',
      'OL21',
      'HG21',
      'DELETE',
      'DOCTOR',
      'BETTER',
      'SOLVED',
      'QUESTION',
      'EAT',
      'POST',
      'DOINGIT',
      'BEAUTY',
      'RUNPOD',
      'MEDIC',
      'GRAZE21',
      'WALL21',
      'FH21',
      'HB21',
      'BODEN20',
      'BODEN21',
      'HG20',
      'BEER21',
      'OL21',
      'SCOTTS21',
      'CF20',
      'WILD21',
      'COAST21',
      'CL20',
      'CL21',
      'EL21',
      'GH20',
      'GH21',
      'HABA21',
      'RED21',
      'TS21',
      'JAN20',
      'TOUCAN21',
    ],
    totalDiscountSentence: `£20 off across your first 2 boxes`,
    futureDiscountSentence: 'Plus £10 off your next box too!',
    thisBoxDiscount: '£10 off',
    discountPeriod: 2,
  },
  {
    code: '10X4',
    totalDiscountSentence: `£40 off across your first 4 boxes`,
    futureDiscountSentence: 'Plus £30 off across your next 3 boxes too!',
    thisBoxDiscount: '£10 off',
    discountPeriod: 4,
  },
  {
    code: '25X2',
    totalDiscountSentence: '25% off your 1st + 2nd box',
    futureDiscountSentence: 'Plus 25% off your second box too!',
    thisBoxDiscount: '25% off',
    discountPeriod: 2,
  },
  {
    code: ['25X4', '@'],
    totalDiscountSentence: '25% off your first 4 boxes',
    futureDiscountSentence: 'Plus 25% off your next 3 boxes too!',
    thisBoxDiscount: '25% off',
    discountPeriod: 4,
  },
  {
    code: ['30X4'],
    totalDiscountSentence: '30% off your first 4 boxes',
    futureDiscountSentence: 'Plus 30% off your next 3 boxes too!',
    thisBoxDiscount: '30% off',
    discountPeriod: 4,
  },
  {
    code: ['35X4', 'SPRING35'],
    totalDiscountSentence: '35% off your first 4 boxes',
    futureDiscountSentence: 'Plus 35% off your next 3 boxes too!',
    thisBoxDiscount: '35% off',
    discountPeriod: 4,
  },
  {
    code: 'BLOG25',
    totalDiscountSentence: '25% off your first 2 boxes',
    futureDiscountSentence: 'Plus 25% off your next box too!',
    thisBoxDiscount: '25% off',
    discountPeriod: 4,
  },
  {
    code: 'INSTA20',
    totalDiscountSentence: '£10 off your first 2 boxes',
    futureDiscountSentence: 'Plus £10 off your next box too!',
    thisBoxDiscount: '£10 off',
    discountPeriod: 4,
  },
  {
    code: 'MIN3',
    totalDiscountSentence: 'Free meal in your first 2 boxes',
    futureDiscountSentence: 'Plus free meal in your next box too!',
    thisBoxDiscount: 'Discount',
    discountPeriod: 2,
  },
  {
    code: 'HEALTHYJAN',
    totalDiscountSentence:
      'Get £10 off your first 2 boxes AND our recipe book worth £20',
    futureDiscountSentence:
      "You'll get our recipe book worth £20 AND £10 off your next delivery too!",
    thisBoxDiscount: '£10 off',
    discountPeriod: 2,
  },
  {
    code: 'NEW-A1',
    totalDiscountSentence:
      '25% off, then 10% off 2 boxes, plus another 25% off',
    futureDiscountSentence:
      'Plus 10% off your next 2 boxes, then another 25% off',
    thisBoxDiscount: '25% off',
    discountPeriod: 4,
  },
  {
    code: 'NEW-B1',
    totalDiscountSentence:
      '20% off, then 10% off 2 boxes, plus another 20% off',
    futureDiscountSentence:
      'Plus 10% off your next 2 boxes, then another 20% off',
    thisBoxDiscount: '20% off',
    discountPeriod: 4,
  },
  {
    code: 'NEW-C1',
    totalDiscountSentence:
      '30% off, then 10% off 2 boxes, plus another 20% off',
    futureDiscountSentence:
      'Plus 10% off your next 2 boxes, then another 20% off',
    thisBoxDiscount: '30% off',
    discountPeriod: 4,
  },
]

export default coreDiscountsConfig
